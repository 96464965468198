import { useLocation } from "react-router-dom";
import * as Some from "../../Components";
import {
  Text,
  Card,
  Button,
  Textarea,
  Modal,
  Grid,
  Accordion,
} from "@mantine/core";
import { memo, useCallback, useEffect, useState } from "react";
import {
  getClient,
  getClientCalls,
  getClientSource,
  getDocs,
  saveClient,
  SubmitComment,
} from "../../functions/api";
import AddInvoiceScreen from "./AddInvoiceScreen";
import { useDisclosure } from "@mantine/hooks";
import { Tree } from "primereact/tree";
import {
  FileInvoice,
  Files,
  MailFast,
  Pencil,
  PhoneCalling,
  CalendarStats,
  Milkshake,
} from "tabler-icons-react";
import AddDocumentScreen from "./AddDocumentScreen";
import { FormattedMessage, useIntl } from "react-intl";
import { EventCard } from "../../Components/Cards/EventCard";
import {
  buttonGroupStyle,
  buttonStyle,
  flexStyle,
} from "../../styles/partnerScreen";
import { CalendarUserSummary } from "../../Components/Calendar";
import EventOverlay from "../../Components/Overlays/EventOverlay";
import { getGroup } from "../../functions/tokens";
import { useSettings } from "../../Hooks/SettingsContext";
import { GetCustomElement } from "../../Custom";
import { env } from "../../env";

function getSubstringAfterLastDot(str) {
  const parts = str.split(".");
  return parts.pop(); // Gets the last element from the array
}

export default function PartnerScreen({ modify = false, newEntry = false }) {
  const intl = useIntl();
  const [tasks, setTasks] = useState([]);
  const [client, setClient] = useState([]);
  const [edit, setEdit] = useState(modify);
  const [srcs, setSrcs] = useState();
  const [overlay, setOverlay] = useState(false);
  const [ovData, setOvdata] = useState();
  const [comments, setComments] = useState();
  const [emails, setEmails] = useState([]);
  const [sidemodal, setSidemodal] = useState(false);
  const [invoices, setInvoices] = useState();
  const [selectedSources, setSelectedSources] = useState([]);
  const [opened, { toggle, close }] = useDisclosure(false);
  const [comment, setComment] = useState("");
  const [callLog, setCallLog] = useState([]);
  const [files, setFiles] = useState([]);
  const [events, setEvents] = useState([]);
  const [columns, setColumns] = useState([]);
  const [calModal, setCal] = useState(false);
  const [eventOverlay, setEventOverlay] = useState(false);

  const { general } = useSettings();

  const showCalendar =
    (general && general.calendar == 1) ||
    (general && getGroup() < 10 && general.adminCalendar == 1);

  const taskOverlay = (data) => {
    setOvdata(data);
    setOverlay(true);
  };

  const location = useLocation();
  let routes = location.pathname.split("/");

  const handleEdit = async (type) => {
    setEdit(!edit);
    if (type == "save") {
      const retid = await saveClient({ ...client, cl: columns }, newEntry);
      window.location.replace("/partner/" + retid[0].clientid);
    }
  };

  useEffect(() => {
    async function fetchClientSources() {
      const sources = await getClientSource();
      setSrcs(sources);
    }
    fetchClientSources();
  }, []);

  useEffect(() => {
    if ((routes[2] && routes[2] == "new") || newEntry == true) {
      setEdit(true);
    } else {
      getClient(routes[2], false).then((res) => {
        setClient(res.clientInfo);
        setTasks(res.tasks);
        setComments(res.comments);
        setInvoices(res.invoices);
        setEmails(res.emails);
        setEvents(res.events);
        getClientCalls({ clientid: routes[2] }).then((x) =>
          setCallLog(x.callLogEntries)
        );
        getDocs({ clientid: routes[2] }).then((x) => setFiles(x));
        setColumns(res.columns);
      });
    }
  }, [edit, sidemodal, ovData, overlay, comment]);

  useEffect(() => {
    routes[2] != "new" &&
      newEntry == false &&
      client.sources &&
      setSelectedSources(client.sources);
  }, [client]);

  const handleSourceChange = useCallback(
    (values) => {
      setSelectedSources(values);
      handleInputChange("sources", values);
    },
    [selectedSources]
  );

  const handleInputChange = useCallback(
    (fieldName, newValue) => {
      setClient((prevState) => ({
        ...prevState,
        [fieldName]: newValue,
      }));
    },
    [client]
  );

  const submitComment = () => {
    SubmitComment({ comment: comment, clientid: client.clientid });
    close();
    setComment("");
  };

  const updateColumn = (column_id, value) => {
    const updatedColumns = columns.map((item) => {
      if (item.column_id === column_id) {
        return { ...item, column_value: value };
      }
      return item;
    });
    setColumns(updatedColumns);
  };

  const getSortedData = useCallback(() => {
    const sortedData = [];

    if (files) {
      const types = {};

      files.forEach((d) => {
        const type = d.type.split("/")[0]; // Extract the first part of the type

        if (!types[type]) {
          types[type] = [];
        }

        types[type].push({
          key: `${type}-${types[type].length}`,
          label: d.docname + "." + getSubstringAfterLastDot(d.originalname),
          url: d.docurl,
          icon: "pi pi-fw pi-" + (type.includes("image") ? "image" : "file"),
        });
      });

      Object.keys(types)
        .sort() // Sort the types alphabetically
        .forEach((type, index) => {
          sortedData.push({
            key: `${index}`,
            label: type,
            children: types[type],
          });
        });
    }

    return sortedData;
  }, [files]);

  const nodeTemplate = (node, options) => {
    let label = <b>{node.label}</b>;

    if (node.url) {
      label = (
        <a
          href={node.url}
          rel="noopener noreferrer"
          download={node.label}
          style={{ cursor: "pointer" }}
        >
          {node.label}
        </a>
      );
    }

    return <span className={options.className}>{label}</span>;
  };

  const displayMore = true;

  const activeCardStyle = {
    marginTop: 5,
    backgroundColor: "white",
    borderRadius: 9,
    border: "0.0625rem solid #dee2e6",
  };

  return (
    <>
      <EventOverlay
        open={eventOverlay}
        setOpen={setEventOverlay}
        data={
          client.clientid
            ? {
                clients: [
                  {
                    ...client,
                    value: client.clientid,
                    label: `${client.c_name ? client.c_name : ""} ${
                      client.c_surname ? client.c_surname : ""
                    } ${client.c_email ? client.c_email : ""} ${
                      client.c_phone ? client.c_phone : ""
                    }`,
                  },
                ],
                client_ids: [client.clientid],
              }
            : null
        }
      />
      <Modal
        opened={calModal}
        onClose={() => setCal(false)}
        title={calModal?.title}
      >
        {calModal?.items?.length > 0 ? (
          <div>
            {calModal?.items?.map((item) =>
              item.taskid ? (
                <Some.TaskItem
                  task={item}
                  displayData={false}
                  onClick={taskOverlay}
                />
              ) : (
                <EventCard event={item} />
              )
            )}
          </div>
        ) : (
          <FormattedMessage id="nothing_found" />
        )}
      </Modal>
      <Modal
        opened={opened}
        onClose={close}
        title={intl.formatMessage({ id: "add_comment" })}
        yOffset="30vh"
      >
        <Textarea
          value={comment}
          onChange={(text) => setComment(text.target.value)}
        />
        <Button
          style={{ marginTop: 20 }}
          onClick={submitComment}
          variant="outline"
        >
          <FormattedMessage id="add_comment" />
        </Button>
      </Modal>
      <Some.SideOverlay
        open={sidemodal}
        setOpen={setSidemodal}
        data={
          sidemodal == "invoice"
            ? {
                component: (
                  <AddInvoiceScreen
                    preclient={
                      client && {
                        ...client,
                        ...{ name: client.c_name, surname: client.c_surname },
                      }
                    }
                  />
                ),
              }
            : {
                component: (
                  <AddDocumentScreen
                    preclient={
                      client && {
                        ...client,
                        ...{ name: client.c_name, surname: client.c_surname },
                      }
                    }
                  />
                ),
              }
        }
      />{" "}
      {client &&
        (client.c_closed ||
          (client?.reason?.length > 0 && client.reason[0] !== null)) && (
          <div
            style={{
              padding: 5,
              marginBottom: 10,
              marginTop: 10,
              width: "100%",
              textAlign: "center",
              backgroundColor: client.c_closed ? "#8a0000" : "orange",
              color: "#f1f1f1",
            }}
          >
            {client.c_closed &&
              client?.reason?.length > 0 &&
              client?.reason[0] !== null && (
                <>
                  {" "}
                  <FormattedMessage id="client_closed_on" />
                  {new Date(client.c_closed).toLocaleDateString()}
                  {" -  "}
                </>
              )}
            <span style={{ fontWeight: "bold" }}>{client.reason[0]}</span>
            {!client.c_closed &&
              client?.reason?.length > 0 &&
              client.reason[0] !== null && (
                <>
                  {" - "}
                  <FormattedMessage id="client_reopened" />
                </>
              )}
          </div>
        )}
      {client && client.clientid && (
        <div style={{ paddingTop: 10 }}>
          <Some.PinButton itemType={"client"} itemId={client.clientid} />
        </div>
      )}
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          flexWrap: "wrap",
          padding: 5,
        }}
      >
        <Some.TaskOverlay
          open={overlay}
          setOpen={setOverlay}
          data={ovData}
          callBack={null}
        />
        {/* {edit && newEntry == false && ( */}
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "end",
            marginBottom: -30,
          }}
        >
          {edit && newEntry == false && (
            <Button
              variant="outline"
              onClick={handleEdit}
              color="red"
              radius={0}
              style={{ width: "auto" }}
            >
              X
            </Button>
          )}
          {!edit && (
            <Button
              variant="outline"
              onClick={handleEdit}
              radius={0}
              color="#00984E"
              style={{
                // marginTop: 25,
                minWidth: 95,
                maxWidth: 115,
                width: "fit-content",
                backgroundColor: "#00984E",
                color: "white",
              }}
            >
              <Pencil
                size={18}
                strokeWidth={2}
                color={"white"}
                style={{ marginRight: 5 }}
              />
              <FormattedMessage id="edit" />
            </Button>
          )}
        </div>
        <div style={flexStyle}>
          <ClientBasicData
            data={client}
            containerStyle={{ minHeight: 210 }}
            edit={edit}
            callBack={handleInputChange}
          />
          <ClientContactInfo
            data={client}
            edit={edit}
            containerStyle={{ minHeight: 170 }}
            callBack={handleInputChange}
          />
          {columns && columns.length > 0 && (
            <ClientBankInfo
              containerStyle={{ minHeight: 130, marginTop: 20 }}
              data={client}
              edit={edit}
              callBack={handleInputChange}
            />
          )}
        </div>
        <div style={flexStyle}>
          <Some.RadioInputs
            containerStyle={{ minHeight: 80 }}
            data={{
              name: "person-type",
              title: "Client type",
              comment: null,
              selects: [
                {
                  id: "physical",
                  label: "Physical person",
                  selected: true,
                },
                {
                  id: "juridical",
                  label: "Juridical person",
                },
              ],
            }}
            selected={client && client.c_type && client.c_type}
            edit={edit}
            callBack={(val) => handleInputChange("c_type", val)}
          />

          {columns.length == 0 && (
            <ClientBankInfo
              containerStyle={{ minHeight: 130, marginTop: 20 }}
              data={client}
              edit={edit}
              callBack={handleInputChange}
            />
          )}

          {columns && columns.length > 0 && (
            <div>
              <Text>{intl.formatMessage({ id: "extra_fields" })}</Text>
              <Card withBorder radius="md">
                <Grid>
                  {columns.map((column) => {
                    return (
                      <Grid.Col
                        xs={column.column_type == "sentences" ? 12 : 6}
                        key={column.column_id}
                      >
                        <Some.EditableLabels
                          edit={edit}
                          label={column.column_name}
                          // placeholder={intl.formatMessage({ id: field.placeholder })}
                          data={column.column_value && column.column_value}
                          type="text"
                          onInputChange={(val) =>
                            updateColumn(column.column_id, val)
                          }
                        />
                      </Grid.Col>
                    );
                  })}
                </Grid>
              </Card>
            </div>
          )}
          {client &&
            (client?.created_name != null ||
              client?.created_surname != null ||
              client?.created_email != null) && (
              <Card
                withBorder
                radius="md"
                style={{ marginTop: 20, marginBottom: 10 }}
              >
                <Some.EditableLabels
                  edit={false}
                  label={intl.formatMessage({ id: "created_by" })}
                  data={
                    client &&
                    client?.created_name +
                      " " +
                      client?.created_surname +
                      " (" +
                      client?.created_email +
                      ")"
                  }
                  withAsterisk={false}
                />
              </Card>
            )}

          <Some.EditableMulti
            selectedSources={selectedSources}
            edit={edit}
            srcs={srcs}
            onInputChange={(values) => {
              handleSourceChange(values);
            }}
          />
        </div>

        {!newEntry && (
          <div style={flexStyle}>
            <div style={{ marginTop: 23 }}>
              <Accordion multiple variant="separated" chevronPosition="left">
                <Some.ToggleBox
                  icon={<FileInvoice size={25} color="green" />}
                  panelStyle={true}
                  style={activeCardStyle}
                  title={
                    <>
                      <FormattedMessage id="invoices" />{" "}
                      {invoices?.length > 0 && `(${invoices.length})`}
                    </>
                  }
                  content={
                    invoices?.length > 0 &&
                    invoices?.map((inv, index) => (
                      <Some.InvoiceCard key={index} invoice={inv} />
                    ))
                  }
                />

                <Some.ToggleBox
                  icon={<PhoneCalling size={25} color="blue" />}
                  panelStyle={true}
                  style={activeCardStyle}
                  title={
                    <>
                      {" "}
                      <FormattedMessage id="calls" />{" "}
                      {callLog?.length > 0 && `(${callLog.length})`}
                    </>
                  }
                  content={
                    callLog &&
                    callLog?.length > 0 && <Some.CallCard callLog={callLog} />
                  }
                />

                <Some.ToggleBox
                  icon={<Files size={25} color="orange" />}
                  panelStyle={true}
                  style={activeCardStyle}
                  title={
                    <>
                      {" "}
                      <FormattedMessage id="files" />{" "}
                      {files?.length > 0 && `(${files.length})`}
                    </>
                  }
                  content={
                    <Tree
                      nodeTemplate={nodeTemplate}
                      filter={files && files.length > 0}
                      filterMode="lenient"
                      filterPlaceholder="Search for a File"
                      value={files && files.length > 0 && getSortedData()}
                      style={{ padding: 3, border: "none" }}
                      className="w-full"
                    />
                  }
                />
                <Some.ToggleBox
                  panelStyle={true}
                  style={activeCardStyle}
                  title={
                    <>
                      <FormattedMessage id="emails" />{" "}
                      {emails?.length > 0 && `(${emails.length})`}
                    </>
                  }
                  icon={<MailFast size={25} color="purple" />}
                  content={
                    emails &&
                    emails?.length > 0 && <Some.EmailList emails={emails} />
                  }
                />
                {showCalendar && (
                  <Some.ToggleBox
                    panelStyle={true}
                    icon={<Milkshake size={25} color="yellowgreen" />}
                    style={activeCardStyle}
                    partnerCard={true}
                    title={
                      <>
                        <FormattedMessage id="events" />{" "}
                        {events?.length > 0 && `(${events.length})`}
                      </>
                    }
                    content={
                      events?.length > 0 &&
                      events.map((event) => <EventCard event={event} />)
                    }
                  />
                )}

                {client?.clientid && showCalendar && (
                  <Some.ToggleBox
                    panelStyle={true}
                    icon={<CalendarStats size={25} color="red" />}
                    style={activeCardStyle}
                    partnerCard={true}
                    title={
                      <>
                        <FormattedMessage id="calendar" />
                      </>
                    }
                    content={
                      <CalendarUserSummary
                        openModal={setCal}
                        events={events && events}
                        tasks={tasks && tasks}
                        id={client && client?.clientid}
                      />
                    }
                  />
                )}
              </Accordion>
            </div>
          </div>
        )}
      </div>
      <hr style={{ marginTop: 10, marginBottom: 10 }} />
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          flexWrap: "wrap",
          padding: 5,
        }}
      >
        {!edit && (
          <div style={buttonGroupStyle}>
            <Button
              onClick={() => taskOverlay({ ...client, old: false })}
              variant="outline"
              color="teal"
              radius={0}
              style={buttonStyle}
            >
              <FormattedMessage id="new_task" /> /{" "}
              <FormattedMessage id="close_client" />
            </Button>
            <Button
              variant="outline"
              onClick={() => setSidemodal("invoice")}
              color="orange"
              radius={0}
              style={buttonStyle}
            >
              <FormattedMessage id="issue_invoice" />
            </Button>
            <Button
              variant="outline"
              onClick={toggle}
              color="grape"
              radius={0}
              style={buttonStyle}
            >
              <FormattedMessage id="add_comment" />
            </Button>
            <Button
              variant="outline"
              onClick={() => setSidemodal("document")}
              color="cyan"
              radius={0}
              style={buttonStyle}
            >
              <FormattedMessage id="upload_file" />
            </Button>
            {showCalendar && (
              <Button
                variant="outline"
                onClick={() => setEventOverlay(true)}
                color="pink"
                radius={0}
                style={buttonStyle}
              >
                <FormattedMessage id="create_event" />
              </Button>
            )}
            <Some.CallButton client={client} />
            {env?.custom && (
              <GetCustomElement
                style={buttonStyle}
                custom={env.custom}
                args={columns}
              />
            )}
          </div>
        )}
        {edit && (
          <Button
            fullWidth
            style={{
              backgroundColor: "dodgerblue",
              marginTop: 30,
            }}
            onClick={() => handleEdit("save")}
          >
            <FormattedMessage id="save_client" />
          </Button>
        )}
      </div>
      <Grid gutter={1}>
        <Grid.Col xs={4}>
          <div>
            {comments && comments.length > 0 && (
              <div>
                <h4
                  style={{
                    marginTop: 10,
                    fontWeight: "bold",
                    paddingLeft: 5,
                    paddingRight: 5,
                  }}
                >
                  <FormattedMessage id="comments" />
                </h4>
                <Card
                  withBorder
                  radius="md"
                  style={{
                    maxHeight: 400,
                    overflow: "hidden",
                    overflowY: "auto",
                    padding: 10,
                    margin: 5,
                  }}
                >
                  {comments.map((cm) => (
                    <Some.Comment
                      key={cm.comment_id}
                      postedAt={cm.created}
                      body={cm.comment}
                      author={
                        cm?.name + " " + (cm?.surname != null ? cm.surname : "")
                      }
                    />
                  ))}
                </Card>
              </div>
            )}
          </div>
        </Grid.Col>
      </Grid>
      <h3
        style={{
          marginTop: 10,
          fontWeight: "bold",
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        {newEntry == false && tasks.length > 0 && "Tasks"}
      </h3>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Some.TaskList
          tasks={tasks}
          onClick={taskOverlay}
          displayData={{
            showCheckbox: displayMore,
            showAsignedTo: displayMore,
            showCreatedBy: displayMore,
            showCreatedOn: displayMore,
            showDeadline: displayMore,
          }}
        />
      </div>
      <div style={{ marginBottom: 100 }}></div>
    </>
  );
}

export const ParnterScreenFields = ({
  edit = false,
  data,
  containerStyle,
  callBack,
}) => {
  return (
    <>
      <ClientBasicData
        containerStyle={{ flex: 2, marginTop: 15, minWidth: 300 }}
        data={data}
        edit={edit}
        callBack={callBack}
      />
      <ClientContactInfo
        edit={edit}
        data={data}
        containerStyle={{ flex: 2, marginTop: 15, minWidth: 300 }}
        callBack={callBack}
      />
    </>
  );
};

export const ClientBasicData = memo(
  ({ edit = false, data, containerStyle, callBack }) => {
    const intl = useIntl();

    const mappableFields = [
      {
        key: "cbd" + 1,
        col: 12,
        input: "label",
        label: "business_name",
        placeholder: "business_name_ph",
        value: data.c_bussiness_name,
        type: "text",
        cb: "c_bussiness_name",
      },
      {
        key: "cbd" + 2,
        col: 6,
        input: "label",
        label: "client_name",
        placeholder: "client_name_ph",
        value: data.c_name,
        type: "text",
        cb: "c_name",
      },
      {
        key: "cbd" + 3,
        col: 6,
        input: "label",
        label: "client_surname",
        placeholder: "client_surname_ph",
        value: data.c_surname,
        type: "text",
        cb: "c_surname",
      },
      {
        key: "cbd" + 4,
        col: 6,
        input: "label",
        label: "business_id",
        placeholder: "business_id_ph",
        value: data.c_bussinessid,
        type: "text",
        cb: "c_bussinessid",
      },
      {
        key: "cbd" + 5,
        col: 6,
        input: "label",
        label: "vat",
        placeholder: "vat_ph",
        value: data.c_vat,
        type: "text",
        cb: "c_vat",
      },
    ];

    return (
      <>
        <div style={containerStyle}>
          <Text>{intl.formatMessage({ id: "client_data" })}</Text>
          <Card withBorder radius="md">
            <Grid>
              <MappedFields
                mappableFields={mappableFields}
                edit={edit}
                callBack={callBack}
              />
            </Grid>
          </Card>
        </div>
      </>
    );
  }
);

export const ClientContactInfo = memo(
  ({ edit = false, data, containerStyle, callBack }) => {
    const intl = useIntl();

    const mappableFields = [
      {
        key: "cci" + 1,
        col: 8,
        input: "label",
        label: "email",
        placeholder: "email_ph",
        value: data.c_email,
        type: "text",
        cb: "c_email",
      },
      {
        key: "cci" + 2,
        col: 6,
        input: "label",
        label: "phone_nr",
        placeholder: "phone_nr_ph",
        value: data.c_phone,
        type: "text",
        cb: "c_phone",
      },
      {
        key: "cci" + 3,
        col: 6,
        input: "label",
        label: "jur_address",
        placeholder: "jur_address_ph",
        value: data.c_jur_address,
        type: "text",
        cb: "c_jur_address",
      },
      {
        key: "cci" + 4,
        col: 6,
        input: "label",
        label: "phys_address",
        placeholder: "phys_address_ph",
        value: data.c_act_address,
        type: "text",
        cb: "c_act_address",
      },
    ];
    return (
      <>
        <div style={containerStyle}>
          <Text>{intl.formatMessage({ id: "contact_info" })}</Text>
          <Card withBorder radius="md">
            <Grid>
              <MappedFields
                mappableFields={mappableFields}
                edit={edit}
                callBack={callBack}
              />
            </Grid>
          </Card>
        </div>
      </>
    );
  }
);

export const ClientBankInfo = memo(
  ({ edit = false, data, containerStyle, callBack }) => {
    const intl = useIntl();

    const mappableFields = [
      {
        key: "cbi" + 1,
        col: 6,
        input: "label",
        label: "clients_bank",
        placeholder: "clients_bank_ph",
        value: data.c_bank,
        type: "text",
        cb: "c_bank",
      },
      {
        key: "cbi" + 2,
        col: 6,
        input: "label",
        label: "bank_swift",
        placeholder: "bank_swift_ph",
        value: data.c_swift,
        type: "text",
        cb: "c_swift",
      },
      {
        key: "cbi" + 3,
        col: 12,
        input: "label",
        label: "bank_account",
        placeholder: "bank_account_ph",
        value: data.c_bank_account,
        type: "text",
        cb: "c_bank_account",
      },
    ];

    return (
      <>
        <div style={containerStyle}>
          <Text>{intl.formatMessage({ id: "client_bank_info" })}</Text>
          <Card withBorder radius="md">
            <Grid>
              <MappedFields
                mappableFields={mappableFields}
                edit={edit}
                callBack={callBack}
              />
            </Grid>
          </Card>
        </div>
      </>
    );
  }
);

const MappedFields = ({ mappableFields, edit, callBack }) => {
  const intl = useIntl();

  return (
    <>
      {mappableFields?.map((field) => (
        <Grid.Col xs={field.col} key={field.key}>
          <Some.EditableLabels
            edit={edit}
            label={intl.formatMessage({ id: field.label })}
            placeholder={intl.formatMessage({ id: field.placeholder })}
            data={field.value && field.value}
            type="text"
            onInputChange={(val) => callBack(field.cb, val)}
          />
        </Grid.Col>
      ))}
    </>
  );
};
