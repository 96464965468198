import { Button } from "@mantine/core";
import { useEffect, useState } from "react";
import {
  CUSTOMgetClientAPIstatus,
  CUSTOMsendClientAPIstatus,
} from "../functions/api";

export const GetCustomElement = ({ style, custom, args }) => {
  console.log(custom, args);
  if (custom == "lmrf-send") {
    console.log("calling element");
    return <LmrfSend data={args[0]} style={style} />;
  }
};

export const LmrfSend = ({ data, style }) => {
  const [status, setStatus] = useState([]);
  console.log(data);

  useEffect(() => {
    if (data?.clientid) {
      CUSTOMgetClientAPIstatus({ clientid: data.clientid }).then((x) => {
        setStatus(x);
        console.log("returning:", x);
      });
    }
  }, [data]);

  const submit = () => {
    if (data?.clientid) {
      CUSTOMsendClientAPIstatus({
        clientid: data.clientid,
        column_name: data.column_name,
        column_value: data.column_value,
      }).then((x) => console.log(x));
    }
  };

  return status?.length == 0 ? (
    <Button
      onClick={submit}
      style={{ ...style, ...{ backgroundColor: "green" } }}
    >
      Sūtīt kadastru novērtējumam
    </Button>
  ) : status[0].raw == "submitted" ? (
    <Button
      style={{ ...style, ...{ backgroundColor: "grey", cursor: "default" } }}
    >
      Nosūtīts novērtējumam
    </Button>
  ) : (
    <Button
      disabled
      style={{ ...style, ...{ backgroundColor: "grey", cursor: "default" } }}
    >
      Novērtējums saņemts
    </Button>
  );
};
